import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import FoodDebt from "./FoodDebt"; // Assuming FoodDebt component exists
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";

const FoodDebtDialog = ({ open, handleClose, contractInfo, data }) => {
  const onCloseHandler = () => {
    handleClose();
  };
  const entities = useSelector(({ entitiesTypes }) => entitiesTypes);
  console.log(`contractInfo`, contractInfo);
  console.log("data", data);
  const styles = {
    subtitle: {
      paddingBottom: "5px",
    },
    span: {
      fontWeight: 400,
    },
  };
  return (
    <div>
      <Dialog open={open} onClose={onCloseHandler} maxWidth="xl" fullWidth>
        {/* <DialogTitle>Питание</DialogTitle> */}
        <DialogActions style={{ float: "right", marginBottom: "-50px" }}>
          <Button onClick={onCloseHandler}>X</Button>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            <Stack>
              <Typography variant="h6" component="span" sx={styles.subtitle}>
                Номер договора:{" "}
                <span style={styles.span}>{contractInfo?.number}</span>
              </Typography>
              <Typography variant="h6" component="span" sx={styles.subtitle}>
                Клиент:{" "}
                <span style={styles.span}>{contractInfo?.full_name}</span>{" "}
                Ребенок:{" "}
                <span style={styles.span}>{contractInfo?.kid_name}</span> Курс:{" "}
                <span style={styles.span}>
                  {findLabelByID(contractInfo?.course, entities?.course)}
                </span>{" "}
                Группа:{" "}
                <span style={styles.span}>
                  {findLabelByID(contractInfo?.group_id, entities?.group)}
                </span>
              </Typography>
              <Typography variant="h6" component="span" sx={styles.subtitle}>
                {/* Статус:{" "}
            <span style={styles.span}>{statuses[contractInfo?.status]}</span> */}
              </Typography>
            </Stack>
          </DialogContentText>
          {contractInfo ? (
            <FoodDebt
              contractInfo={contractInfo}
              data={data}
              closeDialog={onCloseHandler}
            />
          ) : (
            <DialogContentText>
              Данные по договору отсутствуют.
            </DialogContentText>
          )}
        </DialogContent>
        {/* <DialogActions style={{ justifyContent: "flex-start" }}>
          <Button onClick={onCloseHandler} variant="contained">
            Закрыть
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default FoodDebtDialog;
