import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  TextField,
} from "@mui/material";
import AdapterDayjs from "@mui/lab/AdapterDayjs";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ruLocale from "dayjs/locale/ru";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { log } from "../../utils/helpers/tracker";

dayjs.extend(localizedFormat);
dayjs.locale(ruLocale);

const EditLessonDialog = ({
  open,
  handleClose,
  lesson,
  handleSaveChanges,
  entities,
  addAppointment,
  updAppointment,
}) => {
  const [editingLesson, setEditingLesson] = useState({});
  const [actionType, setActionType] = useState(null);
  const teachers = entities.user;
  const subjects = entities.subject;
  const groups = entities.group;
  // console.log("entities", entities);
  const duration = [
    { value: "0:45", label: "1 x 45" },
    { value: "01:00", label: "60" },
    { value: "01:30", label: "2 x 45" },
    { value: "02:15", label: "3 x 45" },
    { value: "03:00", label: "4 x 45" },
  ];

  const daysOfWeekMap = {
    понедельник: 1,
    вторник: 2,
    среда: 3,
    четверг: 4,
    пятница: 5,
    суббота: 6,
    воскресенье: 7,
  };

  // console.log("ПРИШЕЛ В ОКНО", lesson);

  React.useEffect(() => {
    if (!lesson) {
      return;
    }
    const editable = lesson.lesson;
    if (lesson?.type === "edit") {
      setEditingLesson(editable);
      setActionType("edit");
    } else if (lesson?.type === "add") {
      // Get the corresponding day number
      const dayNumber = daysOfWeekMap[editable.dayOfWeek];
      // Parse the time
      const [hours, minutes] = editable.time.split(":").map(Number);
      const now = dayjs();
      // Adjust for Sunday (0 in dayjs) to be 7
      const currentDayNumber = now.day() === 0 ? 7 : now.day();

      // Calculate the start of the current week
      const startOfCurrentWeek = now.subtract(currentDayNumber - 1, "day");

      const weekType = editable.week;
      // Adjust the start of the week based on the week type
      let targetWeekStart;
      if (weekType === "last") {
        targetWeekStart = startOfCurrentWeek.subtract(1, "week");
      } else if (weekType === "next") {
        targetWeekStart = startOfCurrentWeek.add(1, "week");
      } else {
        targetWeekStart = startOfCurrentWeek;
      }

      // Get the desired date
      const targetDate = targetWeekStart
        .add(dayNumber - 1, "day")
        .hour(hours)
        .minute(minutes)
        .second(0);

      // Format the date in the desired format
      const formattedDate = targetDate.format("YYYY-MM-DDTHH:mm");

      setEditingLesson({
        date: formattedDate,
        duration: "0:45",
        group_id: editable.group.value,
        subject: "",
        user_id: "",
      });
      setActionType("add");
    }
    // console.log("editingLESSON", editingLesson);
  }, [lesson, open]);

  const handleInputsChange = (e) => {
    const { name, value } = e.target;
    setEditingLesson((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const avaliableProperties = [
    "date",
    "id",
    "is_substitute",
    "ststus",
    "user_id",
    "subject",
    "group_id",
    "duration",
  ];

  const handleSave = () => {
    if (actionType === "add") {
      console.log("add", editingLesson);
      addAppointment({
        appointment: editingLesson,
      });
      handleSaveChanges(editingLesson);
      // ** Tracking the event start
      log(
        "Appointment Added",
        { appointmentDetails: { ...editingLesson } },
        { type: "Appointment Interaction" }
      );
      // ** Tracking the event end
    }
    if (actionType === "edit") {
      console.log("EDIT_SEND", editingLesson);
      const preperedData = Object.keys(editingLesson).reduce((result, key) => {
        if (avaliableProperties.includes(key)) {
          result[key] = editingLesson[key];
        }
        return result;
      }, {});
      preperedData.date = dayjs(preperedData.date).format("YYYY-MM-DDTHH:mm");
      console.log("EDIT_SEND", preperedData);
      updAppointment({
        appointment: preperedData,
      });
      handleSaveChanges(editingLesson);
      // ** Tracking the event start
      log(
        "Appointment Edited",
        { appointmentDetails: { ...preperedData } },
        { type: "Appointment Interaction" }
      );
      // ** Tracking the event end
    }
    setEditingLesson({});
    // forward to the parent
    handleClose();
  };

  const handleCancel = () => {
    setEditingLesson({});
    handleClose();
  };

  const handleRemove = () => {};

  const [selectedTime, setSelectedTime] = useState(dayjs(editingLesson?.date));

  const handleDateChange = (time) => {
    const date = dayjs(time).format("YYYY-MM-DDTHH:mm");
    console.log("date", date);
    setEditingLesson((prev) => ({
      ...prev,
      date,
    }));
    setSelectedTime(time);
  };

  if (!editingLesson) return null;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Редактирование урока</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TextField
            margin="dense"
            size="small"
            label="Преподаватель"
            fullWidth
            select
            name="user_id"
            value={editingLesson.user_id || ""}
            onChange={(event) => handleInputsChange(event)}
          >
            {teachers?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Предмет"
            fullWidth
            select
            name="subject"
            value={editingLesson.subject || ""}
            onChange={(event) => handleInputsChange(event)}
          >
            {subjects?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="ru">
              <DateTimePicker
                label="Выберите дату и время"
                value={editingLesson.date}
                onChange={(newValue) => handleDateChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <TextField
            margin="dense"
            size="small"
            label="Группа"
            fullWidth
            select
            name="group_id"
            value={editingLesson.group_id || ""}
            onChange={(event) => handleInputsChange(event)}
          >
            {groups?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Продолжительность"
            fullWidth
            select
            name="duration"
            value={editingLesson.duration || ""}
            onChange={(event) => handleInputsChange(event)}
          >
            {duration?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="warning" onClick={handleRemove}>
          Удалить назначение
        </Button>
        <Button onClick={handleCancel}>Отмена</Button>
        <Button onClick={handleSave}>Сохранить изменения</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLessonDialog;
